body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*:focus {
  outline:none !important; 
  box-shadow: none; 
  border: none;
}
#root {
  overflow: hidden;
}
.btn:focus {
  outline:none !important; 
  box-shadow: none !important; 
  border: none !important;
  background-color: #ffaa00 !important;
}
input.connexionButton:focus {outline:none !important; box-shadow: none; border: none;}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.mt10 {
  margin-top: 10px;
}
/**
*
* Header
*
**/
.bonjour {
  margin: 0;
  color: #ffffff;
}
header {
  height: 80px;
}
.navbar {
  padding: 0;
}
.navbar .containerNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
  margin: 0 40px;
}
.bg-header {
  height: 100%;
  background-color: #33225D;
  z-index: 10;
}
/**
*
* Loading
*
**/
.containerLoadingDash {
  position: absolute;
  left: 0;
  z-index: 20;
  width: 100%;
  height: calc(100vh - 365px);
  background-color: rgba(51, 34, 93, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.containerLoadingDash .loading {
  position: relative;
  width: 20px;
  height: 20px;
  z-index: 50;
  margin-left: 5px;
  top: auto;
  left: auto;
}
.containerLoadingDash p {
  font-family: 'Muli', sans-serif;
  font-weight: bold;
  color: rgb(51, 34, 93);
  font-size: 20px;
  margin-bottom: 6px;
}
.containerLoadingDash .loading svg {
  vertical-align: bottom;
}

.containerLoading {
  position: absolute;
  left: 0;
  z-index: 20;
  width: 100%;
  height: calc(100vh - 80px);
  background-color: rgba(51, 34, 93, 0.15);
}
.loading {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  width: 200px;
  height: 200px;
  border-radius: 100px;
  background-color: #ffffff;
  z-index: 50;
}
.containerEmptyDash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 365px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.containerEmptyDashSearch {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 365px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.containerEmptyDashSearchActif {
  display: flex !important;
}
.containerEmptyDashSearch p {
  font-family: 'Muli', sans-serif;
  font-weight: bold;
  color: #777777;
  font-size: 20px;
}
.containerEmptyDashSearchMy {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 365px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.containerEmptyDashSearchActifMy {
  display: flex !important;
}
.containerEmptyDashSearchMy p {
  font-family: 'Muli', sans-serif;
  font-weight: bold;
  color: #777777;
  font-size: 20px;
}
.containerEmptyDash p {
  font-family: 'Muli', sans-serif;
  font-weight: bold;
  color: #777777;
  font-size: 20px;
}
/**
*
* Login
*
**/
.containerLogin {
  height: calc(100vh - 80px);
  background-color: rgba(51, 34, 93, 0.95);
}
.containerLogin .containerContent {
  width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 100px;
}
.containerLogin .containerContent h1 {
  font-family: 'Muli', sans-serif;
  font-weight: 800;
  color: #ffffff;
  font-size: 52px;
  margin-bottom: 60px;
}
.errTitle {
  font-family: 'Muli', sans-serif;
  font-weight: 800;
  color: #33225D !important;
  font-size: 52px;
  margin-bottom: 60px;
  text-align: center;
}
.containerLogin .containerContent .rightForm {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.containerLogin .containerContent .containerForm {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  background-color: #ffffff;
  border-radius: 27px;
  box-shadow: 0 20px 20px 0 rgba(8, 8, 8, 0.2);
  padding: 30px;
}
.containerLogin .rightForm .containerChamps {
  border-radius: 6px;
  background-color: rgba(51, 34, 93, 0.06);
  padding: 30px;
}

.containerLogin .invalid-feedback {
  display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
    margin: 0;
    padding: 0;
    padding-left: 10px;
}
.containerLogin .is-invalid~.invalid-feedback {
  display: block !important;
}
.containerLogin .rightForm form { 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
}
.containerLogin .connexionButton {
  border-radius: 25px;
  background-color: #33225d;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  align-self: flex-end;
  margin-top: 20px;
  padding: 13px 86px;
  border: none;
  -webkit-transition: background-color;
  -moz-transition: background-color;
  -o-transition: background-color;
  transition: background-color;
  -webkit-transition: ease-in-out;
  -moz-transition: ease-in-out;
  -o-transition: ease-in-out;
  transition: ease-in-out;
  -webkit-transition: 0.125s;
  -moz-transition: 0.125s;
  -o-transition: 0.125s;
  transition: 0.125s;
  outline: none;
}

.containerLogin .connexionButton:hover
{
  background-color: #ffaa00;
}

.containerLogin .formLogin {
  position: relative;
  font-family: Muli;
  font-size: 15px;
  font-weight: 600;
  color: #33225d !important;
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  background-color: #ffffff !important;
  padding: 18px 12px;
  top: 0;
  left: 0;
  z-index: 1;
  outline: 0;
}
.containerLogin input, .containerLogin label, .containerLogin label span, .containerLogin textarea,
.containerDuplicatePop input, .containerDuplicatePop label, .containerDuplicatePop label span, .containerDuplicatePop textarea {
  font-family: "Muli",sans-serif;
  font-weight: 600;
  display: block;
  margin: 10px;
  padding: 11px;
  border: none;
  font-size: 22px;
}
.inputWithIcon input,
.inputWithIcon label span,
.inputWithIcon label,
.inputWithIcon textarea {
  font-family: 'Muli', sans-serif;
  font-weight: 600;
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  font-size: 22px;
}

.containerLogin textarea:focus,
.containerLogin input:focus,
.containerDuplicatePop textarea:focus,
.containerDuplicatePop input:focus {
  outline: 0;
}
/* Question */

.containerLogin input.question,
.containerLogin textarea.question,
.containerDuplicatePop input.question,
.containerDuplicatePop textarea.question {
  font-family: 'Muli', sans-serif;
  font-weight: 600 !important;
  min-height: 55px;
  font-size: 15px;
  color: #33225d;
  font-weight: 300;
  border-radius: 2px;
  margin: 0;
  border: none;
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  background-color: #ffffff;
  transition: padding-top 0.2s ease, padding-bottom 0.2s ease, margin-top 0.2s ease;
  overflow-x: hidden; /* Hack to make "rows" attribute apply in Firefox. */
}
/* Underline and Placeholder */

.containerLogin input.question + label,
.containerLogin textarea.question + label,
.containerDuplicatePop input.question + label,
.containerDuplicatePop textarea.question + label {
  display: block;
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 100%;
  -webkit-transition: width 0.125s ease-in-out;
  transition: width 0.125s ease-in-out;
  height: 0px;
}

.containerLogin input.question:focus + label,
.containerLogin textarea.question:focus + label,
.containerDuplicatePop input.question:focus + label,
.containerDuplicatePop textarea.question:focus + label {
  width: 100%;
}

.containerLogin input.question:focus,
.containerLogin input.question:valid,
.containerDuplicatePop input.question:focus,
.containerDuplicatePop input.question:valid {
  padding-top: 15px;
  padding-bottom: 0px;
}

.containerLogin textarea.question:valid,
.containerLogin textarea.question:focus,
.containerDuplicatePop textarea.question:valid,
.containerDuplicatePop textarea.question:focus {
  margin-top: 35px;
}

.containerLogin input.question:focus + label > span,
.containerLogin input.question:valid + label > span {
  top: -60px;
  font-size: 12px;
  color: rgba(119, 119, 119, 0.5);
}
.containerDuplicatePop input.question:focus + label > span,
.containerDuplicatePop input.question:valid + label > span {
  top: -55px;
  font-size: 12px;
  color: rgba(119, 119, 119, 0.5);
}
.containerLogin input.question:valid + label > img,
.containerDuplicatePop input.question:valid + label > img {
  display: block;  
}

.containerLogin textarea.question:focus + label > span,
.containerLogin textarea.question:valid + label > span,
.containerDuplicatePop textarea.question:focus + label > span,
.containerDuplicatePop textarea.question:valid + label > span {
  top: -150px;
  font-size: 22px;
  color: #333;
}

.containerLogin input.question:valid,
.containerLogin textarea.question:valid,
.containerDuplicatePop input.question:valid,
.containerDuplicatePop textarea.question:valid {
  border: 1px solid #ffaa00 !important;
}

.containerLogin input.question:invalid,
.containerLogin textarea.question:invalid,
.containerDuplicatePop input.question:invalid,
.containerDuplicatePop textarea.question:invalid {
  box-shadow: none;
}

.containerLogin input.question + label > span,
.containerLogin textarea.question + label > span{
  font-weight: 300;
  margin: 0;
  position: absolute;
  color: #777777;
  font-size: 15px;
  top: -50px;
  left: 0px;
  z-index: 0;
  -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}
.containerDuplicatePop input.question + label > span,
.containerDuplicatePop textarea.question + label > span {
  font-weight: 300;
  margin: 0;
  position: absolute;
  color: #777777;
  font-size: 15px;
  top: -40px;
  left: 60px;
  z-index: 0;
  -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}
.containerLogin input.question + label > img,
.containerLogin textarea.question + label > img,
.containerDuplicatePop input.question + label > img,
.containerDuplicatePop textarea.question + label > img {
  display: none;
  margin: 0;
  position: absolute !important;
  top: -35px !important;
  right: 20px !important;
  left: auto !important;
  z-index: 0;
}
.containerLogin input.question:valid ~ input[type="submit"], .containerLogin textarea.question:valid ~ input[type="submit"],
.containerDuplicatePop input.question:valid ~ input[type="submit"], .containerDuplicatePop textarea.question:valid ~ input[type="submit"] {
  -webkit-animation: appear 1s forwards;
  animation: appear 1s forwards;
}

.containerLogin input.question:invalid ~ input[type="submit"], .containerLogin textarea.question:invalid ~ input[type="submit"],
.containerDuplicatePop input.question:invalid ~ input[type="submit"], .containerDuplicatePop textarea.question:invalid ~ input[type="submit"] {
  display: none;
}

/**
*
* Dashboard
*
**/

.containerPop input,
.containerPop span,
.containerPop label,
.containerPop textarea {
  font-family: 'Muli', sans-serif;
  font-weight: 600;
  display: block;
  margin: 10px;
  padding: 11px;
  border: none;
  font-size: 22px;
}

.containerPop textarea:focus,
.containerPop input:focus {
  outline: 0;
}
.creationPop input.questionnaire,
.creationPop textarea.questionnaire {
  font-family: 'Muli', sans-serif;
  font-weight: 600 !important;
  font-size: 15px;
  color: #33225d;
  display: block;
  font-weight: 300;
  border-radius: 2px;
  margin: 0;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  background-color: #ffffff;
  transition: padding-top 0.2s ease, margin-top 0.2s ease;
  overflow-x: hidden; /* Hack to make "rows" attribute apply in Firefox. */
}
/* Underline and Placeholder */

.creationPop input.questionnaire + label,
.creationPop textarea.questionnaire + label {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    font-size: 22px;
    width: 100%;
    transition: width 0.4s ease;
    height: 0;
    left: 0;
}

.creationPop input.questionnaire + label > span
{
  position: absolute !important;
  z-index: 2 !important;
  left: 60px;
  top: -40px;
  font-size: 12px;
  color: rgba(119, 119, 119, 0.5);
}

.creationPop input.questionnaire:focus + label,
.creationPop textarea.questionnaire:focus + label {
  width: 100%;
}

.creationPop input.questionnaire:focus,
.creationPop input.questionnaire:valid {
  padding-top: 20px;
  border: 1px solid #ffaa00 !important;
}

.creationPop textarea.questionnaire:valid,
.creationPop textarea.questionnaire:focus {
  margin-top: 35px;
}

.creationPop input.questionnaire:focus + label > span,
.creationPop input.questionnaire:valid + label > span {
  top: -52px;
  font-size: 12px;
  color: rgba(119, 119, 119, 0.5);
}
.creationPop input.questionnaire:valid + label > img {
  display: block;  
}

.creationPop textarea.questionnaire:focus + label > span,
.creationPop textarea.questionnaire:valid + label > span {
  top: -150px;
  font-size: 22px;
  color: #333;
}

.creationPop input.questionnaire:valid,
.creationPop textarea.questionnaire:valid {
  border: 1px solid #ffaa00;
}

.creationPop input.questionnaire:invalid,
.creationPop textarea.questionnaire:invalid {
  box-shadow: none;
}

.creationPop input.questionnaire + label > span,
.creationPop textarea.questionnaire + label > span {
  font-weight: 300;
  margin: 0;
  position: absolute;
  color: #777777;
  font-size: 15px;
  z-index: 0;
  -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}
.creationPop input.questionnaire + label > img,
.creationPop textarea.questionnaire + label > img {
  display: none;
  margin: 0;
  position: absolute !important;
  top: -38px !important;
  right: 20px;
  left: auto !important;
  z-index: 0;
}

.creationPop input.questionnaire:valid ~ input[type="submit"], .creationPop textarea.questionnaire:valid ~ input[type="submit"] {
  -webkit-animation: appear 1s forwards;
  animation: appear 1s forwards;
}

.creationPop input.questionnaire:invalid ~ input[type="submit"], .creationPop textarea.questionnaire:invalid ~ input[type="submit"] {
  display: none;
}

@-webkit-keyframes appear {
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  100% {
    opacity: 1;
  }
}
.headerDash {
  width: 100%;
}

.emailings {
  background-color: #ffffff;
}
.emailings .container {
  padding: 0;
  width: 80%;
  max-width: 80%;
}
.emailings .container .new {
  position: relative;
  display: flex;
  align-items: center;
  height: 150px;
}
.emailings .container .new .blocTitre {
  position: absolute;
  left: 0;
}


/*****************/
/*** RECHERCHE ***/
/*****************/


.containerSearch {
  padding-top: 10px;
}

.containerSearchInput {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    /*border-bottom: 5px solid #33225d;*/
    height: 49px;
}


.containerSearchInput .searchBorder
{
  z-index: 3;
  border: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0 ;
  background-color: #33225d;
  width: 100%;
  -webkit-transition: height 0.2s ease-in-out 0.2s;
  -moz-transition: height 0.2s ease-in-out 0.2s;
  -o-transition: height 0.2s ease-in-out 0.2s;
  transition: height 0.2s ease-in-out 0.2s;
}

.containerSearchInput img {
  margin-right: 10px;
}

.containerSearchInput .search-input {
  width: 190px;
  height: 100%;
  -webkit-transition: width 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-in-out;
  -o-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
}


.containerSearchInput .search-input input {
  border: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.containerSearchInput .search-input:focus-within {
  width: 300px;
  border: none !important;
  outline: none !important;
}
.containerSearchInput .search-input input::placeholder {
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.containerSearchInput .search-input:focus-within ~ .searchBorder {
  height: 5px;
}

.containerSearchInput .search-input:focus-within input::placeholder {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

#searchId::-webkit-search-cancel-button {
  background-image: url('./img/path.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  width: 8px;
  height: 8px;
  position:relative;
  right:0;  
  cursor: pointer;
  -webkit-appearance: none;
}





.titleDashboard {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.emailings .container .new .blocTitre h1 {
  display: inline-block;
  color: #353535;
  font-family: 'Muli', sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  padding: 10px 10px;
  margin: 0;
}
.emailings .container .new .blocTitre p {
  color: #777777;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
}
.emailings .container .new .blocCreation {
  position: absolute;
  right: 0;
}
.duplicatePopin .form-group input {
  width: 100%;
}
.emailings .container .new .blocCreation a {
  position: relative;
  padding: 13px 20px;
  background-color: #ff9f2d;
  color: #ffffff;
  border-radius: 50px;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  -webkit-transition: padding-right;
  -moz-transition: padding-right;
  -o-transition: padding-right;
  transition: padding-right;
  -webkit-transition: ease-in-out;
  -moz-transition: ease-in-out;
  -o-transition: ease-in-out;
  transition: ease-in-out;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
}
.emailings .container .new .blocCreation a p {
  color: #ffffff;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  margin: 0;
}
.emailings .container .new .blocCreation a img {
  pointer-events: none;
  position: absolute;
  z-index: 2;
  right: 20px;
  transform: scale(0);
  opacity: 0;
  -webkit-transition: transform opacity;
  -moz-transition: transform opacity;
  -o-transition: transform opacity;
  transition: transform opacity;
  -webkit-transition: ease-in-out;
  -moz-transition: ease-in-out;
  -o-transition: ease-in-out;
  transition: ease-in-out;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.buildButton {
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #ff9f2d;
  padding: 10px 22px;
  cursor: pointer;
}
.buildButton p {
  margin: 0;
  margin-right: 15px;
}
.buildButton img {
  height: 22px;
  width: 22px;
}
.emailings .container .new .blocCreation a:hover {
  padding-right: 60px;
}
.emailings .container .new .blocCreation a:hover img {
  transform: scale(1);
  opacity: 1;
}
.txt {
  position: relative;
  width: calc(100% - 90px);
  float: right;
  height: 100%;
  padding-bottom: 10px;
}
.rowList {
  width: 100%;
  height: 100%;
  padding-left: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.emptyJob {
  padding-left: 63px !important;
}
.headerProjectList {
  font-family: 'Muli', sans-serif;
  font-size: 15px;
  color: #777777;
  margin-top: 0 !important;
  padding-top: 30px !important;
  background-color: transparent !important;
  cursor: initial;
  margin-left: 10px;
}
.rowList .col {
  width: calc(80% / 5);
  display: flex;
  align-items: center;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rowListCopy .prog {
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #353535;
  text-overflow: ellipsis;
}
.rowListCopy .col .ville {
  font-family: 'Muli', sans-serif;
  color: #777777;
  padding-top: 5px;
  display: block;
  font-size: 15px;
}
.rowListCopy .col .promoteur {
  font-family: 'Muli', sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #353535;
}


/************/
/** STATUT **/
/************/

.rowListCopy .col .statut {
  color: #ffffff;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  padding: 14px 20px;
  border-radius: 50px;
  text-overflow: nowrap;
}

.rowListCopy .col .statut div {
  margin-left: 10px;
  width: 22px;
  height: 22px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: #ff9f2d;
}

.rowListCopy .col .statut div p {
  font-size: 11px;
  font-weight: bold;
}


.deployDone {
  background-color: #777777;
}
.buildDone {
  background-color: #353535;
}
.interneDone {
  background-color: #ff4c2d;
}
.batDone {
  background-color: #33225D;
  display: flex;
  flex-direction: row;
}
.batDone div {
  background-color: #ffa82d;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-left: 10px;
}
.conception {
  background-color: #ff9f2d;
}
.archive {
  background-color: #6FC30E;
}
.rowList .col .date {
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  color: #777777;
  padding: 0;
  display: inline-block;
}
.gestionProjets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}
.bgContainerProj {
  position: relative;
  min-height: calc(100vh - 365px);
  background-color: rgba(51, 34, 93, 0.06);
  padding-bottom: 200px;
}
.bgUserChoice {
  background-color: rgba(51, 34, 93, 0.06);
  width: 100%;
}
.userChoice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 10px;
}
.projectChoice {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.userChoice p {
  font-family: Muli, sans-serif;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
}
.userLeft, .userRight {
  border: solid 1px #33225d;
  padding: 7.5px 15px;
  cursor: pointer;
}
.userRight {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.userLeft {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
.ongletsProjets {
  display: flex;
  flex-direction: row;
}
.projetsEnCours {
  margin-right: 15px;
  cursor: pointer;
}
.projetsArchives {
  cursor: pointer;
}
.projetsEnCours p {
  padding: 10px;
  cursor: pointer;
  margin: 0;
  color: #777777;
}
.projetsArchives p {
  margin: 0;
  padding: 10px;
  cursor: pointer;
  color: #777777;
}
.containerRechercheProjets {
  display: flex;
  flex-direction: row;
}
.gestionPages {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}
.ongletActif {
  border-bottom: 5px solid #ff9f2d;
  font-weight: 600;
  color: #353535 !important;
}
.ongletActifVert {
  border-bottom: 5px solid #4ac700;
  font-weight: 600;
  color: #353535 !important;
}
.ongletActifVert p {
  font-weight: 600;
  color: #353535;
}
.ongletActif p {
  font-weight: 600;
  color: #353535;
}

.rowListCopy:hover .options {
  visibility: visible;
  opacity: 1;
}
.rowListCopy .options {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    opacity: 0;
    width: 100px;
    visibility: hidden;
}
.rowListCopy .options div {
  padding: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.rowListCopy .options div object {
  pointer-events: none;
}
.rowListCopy .options div:hover .sousnav {
  max-height: 198px;
  width: 150px;
  -webkit-transition: max-height, width;
  -moz-transition: max-height, width;
  -o-transition: max-height, width;
  transition: max-height, width;
  -webkit-transition: cubic-bezier(0.000, 1, 0, 1.000);
  -moz-transition: cubic-bezier(0.000, 1, 0, 1.000);
  -o-transition: cubic-bezier(0.000, 1, 0, 1.000);
  transition: cubic-bezier(0.000, 1, 0, 1.000);
  -webkit-transition: 0.35s;
  -moz-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
  transition-delay: 0.35s;
}
.rowListCopy .options div:hover .sousNavArch {
  width: 180px;
  max-height: 132px;
}
.rowListCopy .options div .sousnav {
  position: absolute;
  padding: 0;
  z-index: 80;
  top: 45px;
  left: 0;
  width: 0;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 12px 50px 0 rgba(51, 34, 93, 0.3);
  background-color: #fff;
  overflow: hidden;
  max-height: 0;
  align-items: start;

}
.rowListCopy .options div .sousnav .list {
  padding: 0;
  width: 100%;
  height: auto;
  max-height: 225px;
  background: 0 0;
}
.rowListCopy .options div .sousnav .list ol {
  height: auto;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.rowListCopy .options div .sousnav .list ol li {
  display: block;
  font-family: Muli, sans-serif;
  font-weight: 400;
  color: #777;
  padding: 20px;
  white-space: nowrap;
}
.rowListCopy .options div .sousnav .list ol li:hover {
  cursor: pointer;
  background-color: #f3f2f6;
}



.rowListCopy:hover .showOptions {
  visibility: visible;
  opacity: 1;
}
.rowListCopy .showOptions {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    opacity: 0;
    width: 100px;
    visibility: hidden;
}
.rowListCopy .showOptions div {
  padding: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.rowListCopy .showOptions div object {
  pointer-events: none;
}
.rowListCopy .showOptions div:hover .sousnav {
  max-height: 198px;
  width: 150px;
  -webkit-transition: max-height, width;
  -moz-transition: max-height, width;
  -o-transition: max-height, width;
  transition: max-height, width;
  -webkit-transition: cubic-bezier(0.000, 1, 0, 1.000);
  -moz-transition: cubic-bezier(0.000, 1, 0, 1.000);
  -o-transition: cubic-bezier(0.000, 1, 0, 1.000);
  transition: cubic-bezier(0.000, 1, 0, 1.000);
  -webkit-transition: 0.35s;
  -moz-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
  transition-delay: 0.35s;
}
.rowListCopy .showOptions div:hover .sousNavArch {
  width: 650px;
  height: 400px;
  max-height: 500px;
}
.rowListCopy .showOptions div .sousnav {
  position: absolute;
  padding: 0;
  z-index: 80;
  width: 0;
  height: auto;
  border-radius: 5px;
  box-shadow: 0 12px 50px 0 rgba(51, 34, 93, 0.3);
  background-color: #fff;
  overflow: hidden;
  max-height: 0;
  align-items: start;
}
.rowListCopy .showOptions div .sousnav iframe {
  width: 100%;
  height: 100%;
}





.groupColDash {
  position: relative;
  width: calc(100% - 100px);
  display: flex;
  align-items: center;
}
.threeDots {
  width: 30px !important;
  cursor: pointer;
  overflow: visible !important;
  justify-content: flex-end !important;
  padding-right: 37px !important;
}
.threeDots img {
  padding: 15px;
  width: 60px;
  height: auto;
}
.dashboardPopChoice {
  position: absolute;
  left: -50px;
  top: 0;
  z-index: 10;
  background-color: #ffffff;
  box-shadow: 0 12px 50px 0 rgba(51, 34, 93, 0.3);
  border-radius: 5px;
}
.dashboardPopChoice p {
  font-family: 'Muli', sans-serif;
  color: #777777;
  font-size: 15px;
  margin: 0;
  padding-bottom: 15px;
  padding-top: 15px;
  cursor: pointer;
  padding-right: 50px;
  padding-left: 20px;
}
.dashboardPopChoice p:hover {
  background-color: #dddddd;
}
/*
.dashboardPopChoiceUnderline {
  border-bottom: 1px solid rgba(51, 34, 93, 0.15);
}
*/
.btn p {
  margin: 0;
}
/**
*
* PAGINATION
*
**/
.pagination-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15px;
  cursor: pointer;
}
.pagination-arrow img {
  width: 100%;
}
.reverse {
  transform: rotate(180deg);
}
.pageChoice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.pageChoice p {
  margin: 0 15px;
  font-weight: 600;
  color: #353535;
  font-size: 15px;
}
.bgContainerProj-pagination {
  padding-bottom: 0;
}
.bottom-choice {
  padding-bottom: 50px;
}
/**
*
* Popup creation
*
**/
.creationPop {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 1000;
}
.duplicatePopin {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 1000;
}
.bgCreationPop,
.bgDuplicatePop {
  background-color: rgba(51, 34, 93, 0.95);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.creationPop .containerContent,
.duplicatePopin .containerDuplicatePop {
  background-color: #ffffff;
  display: flex;
  border-radius: 5px;
  box-shadow: 0 20px 50px 0 rgba(8, 8, 8, 0.6);
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
  padding: 30px;
  height: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content; 
}
.duplicatePopin .containerConfirmPop {
  background-color: #ffffff;
  display: flex;
  border-radius: 5px;
  box-shadow: 0 20px 50px 0 rgba(8, 8, 8, 0.6);
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
  padding: 30px;
  height: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content; 
}
.duplicatePopin .containerConfirmPop .supprPara {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 89px;
}
.duplicatePopin .containerConfirmPop p {
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  color: #353535;
  margin: 0;
}
.duplicatePopin .containerConfirmPop span {
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #353535;
  margin-left: 15px;
}
.creationPop .containerContent .headerPop,
.duplicatePopin .containerConfirmPop .headerPop,
.duplicatePopin .containerDuplicatePop .headerPop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 35px;
}
.creationPop .containerContent .headerPop h2,
.duplicatePopin .containerDuplicatePop .headerPop h2,
.duplicatePopin .containerConfirmPop .headerPop h2 {
  margin: 0;
  font-family: Muli,sans-serif;
  font-weight: 700;
  color: #353535;
  font-size: 28px;
  margin-left: 25px;
}
.creationPop .containerContent .formsPop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.creationPop .containerContent .formsPop .leftForm,
.creationPop .containerContent .formsPop .rightForm {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px;
  border-radius: 6px;
  background-color: rgba(51, 34, 93, 0.06);
}
.creationPop .formsPop .titleForm {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
}
.creationPop .formsPop .titleForm h3 {
  margin: 0;
  font-family: Muli,sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #33225d;
}
.creationPop .formsPop .titleForm .para {
  width: 32px;
  height: 32px;
  background-color: #33225d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  margin-right: 15px;
}
.creationPop .formsPop .titleForm .para p {
  margin: 0;
  font-family: Muli,sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 13.5px;
}
.creationPop .containerContent .formsPop .inputWithIcon,
.duplicatePopin .containerDuplicatePop .formsPop .inputWithIcon {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
}
.creationPop .containerContent .formsPop .inputWithIcon input,
.duplicatePopin .containerDuplicatePop .formsPop .inputWithIcon input {
  width: 100%;
  height: 60px;
}
.creationPop .containerContent .formsPop .inputWithIcon input[type=text],
.duplicatePopin .containerDuplicatePop .formsPop .inputWithIcon input[type=text],
.creationPop .containerContent .formsPop .inputWithIcon input[type=number],
.duplicatePopin .containerDuplicatePop .formsPop .inputWithIcon input[type=number]  {
  padding-left: 60px;
  border-radius: 4px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  background-color: #ffffff;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.creationPop .containerContent .formsPop .inputWithIcon img,
.duplicatePopin .containerDuplicatePop .formsPop .inputWithIcon img
 {
  position: absolute;
  left: 21px;
  top: 19px;
}
.rightForm .choixTemplate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
}
.rightForm .choixTemplate p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 15px;
  font-weight: 600;
}
.rightForm .choixTemplate .emptyDiv {
  width: 20px;
}
.alertMsgCreation {
  position: absolute;
  font-family: Muli,sans-serif;
  font-weight: 700;
  color: #dc3545;
  right: 10px;
  top: -40px;
}
/**
*
* Radio
*
**/
.creationPop .containerContent .formsPop .rightForm .radio {
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  position: relative;
}
.creationPop .containerContent .formsPop .rightForm .radio [type="radio"]:checked,
.creationPop .containerContent .formsPop .rightForm .radio [type="radio"]:not(:checked) {
	position: absolute;
	left: -9px;

}
.creationPop .containerContent .formsPop .rightForm .radio input:checked + label,
.creationPop .containerContent .formsPop .rightForm .radio input:not(:checked) + label{
	position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 10px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  background-color: #ffffff;
  cursor: pointer;
  overflow: hidden;
}
.creationPop .containerContent .formsPop .rightForm .radio input:not(:checked) + label{
	background-color: #ffffff;
}
.creationPop .containerContent .formsPop .rightForm .radio input:checked + label{
	background-color: #33225d;
}
.creationPop .containerContent .formsPop .rightForm .radio input:not(:checked) + label:hover{
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.creationPop .containerContent .formsPop .rightForm .radio input:checked + label::before,
.creationPop .containerContent .formsPop .rightForm .radio input:not(:checked) + label::before{
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	background-color: #33225d;
	z-index: -1;
}
.creationPop .containerContent .formsPop .rightForm .radio input:checked + label img,
.creationPop .containerContent .formsPop .rightForm .radio input:not(:checked) + label img{
  display: block;
  height: 22px;
  width: 22px;
}
.creationPop .containerContent .retourDash,
.duplicatePopin .containerDuplicatePop .retourDash,
.duplicatePopin .containerConfirmPop .retourDash {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.creationPop .containerContent .retourDash img,
.duplicatePopin .containerDuplicatePop .retourDash img,
.duplicatePopin .containerConfirmPop .retourDash img {
  width: 20px;
  height: auto;
  margin-right: 20px;
}
.creationPop .containerContent .formsPop .rightForm .radioPara {
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.creationPop .containerContent .formsPop .rightForm .radioPara p{
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 13px;
  color: #777777;
}
.creationPop .containerContent .formsPop .rightForm .fakeDiv {
  position: relative;
  width: 22px;
  height: 22px;
}
  /**
  *
  * Buttons creation
  *
  **/
.creationPop .containerButtons,
.duplicatePopin .containerButtons {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
}
.creationPop .containerButtons p,
.duplicatePopin .containerButtons p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #33225d;
}
.creationPop .containerButtons input,
.duplicatePopin .containerButtons input {
  position: relative;
  padding: 10px 20px;
  background-color: #ff9f2d;
  color: #ffffff;
  border-radius: 50px;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  border-color: transparent;
  width: fit-content;
}
.duplicatePopin .containerButtons input {
  font-family: Muli;
  font-size: 16px;
  color: #ffffff;
}
/**
*
* Projects
*
**/
.containerProjects {
  width: 80%;
  max-width:  1280px;
  margin: 0 auto;
  position: relative;
}
.containerProjects .containerCurrent {
  width: 100%;
}
.titleSubcontainerCurrent {
  border-bottom: 1px solid rgba(51, 34, 93, 0.15);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 15px;
  cursor: pointer;
}
.titleSubcontainerCurrent p {
  font-family: Muli,sans-serif;
  font-weight: 700;
  font-size: .9em;
  color: #353535;
  margin: 0;
}
.titleSubcontainerCurrent span {
  font-family: Muli,sans-serif;
  font-weight: 400;
  color: #777;
  font-size: .8em;
  padding: 0 10px;
}
.titleSubcontainerCurrent .indicator {
  margin-right: 10px;
  width: 10px;
  height: 10px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
/**
*
* Create Folder
*
**/
.ContainerCreateFolder {
  width: 75%;
  margin: 0 auto;
}
.ContainerCreateFolder form {
  width: 50%;
  margin: 0 auto;
}
.ContainerCreateFolder .champsVille input {
  width: 100%;
}
.ContainerCreateFolder .createButton {
  margin: 0 auto;
  position: relative;
  padding: 20px;
  background-color: #ff9f2d;
  color: #ffffff;
  border-radius: 50px;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  border-color: transparent;
}
/**
*
* Copy
*
**/
.containerCopy {
  width: 75%;
  margin: 0 auto;
}
.containerCopy form {
  width: 50%;
  margin: 0 auto;
}

.containerCopy .createButton {
  margin: 0 auto;
  position: relative;
  padding: 20px;
  background-color: #ff9f2d;
  color: #ffffff;
  border-radius: 50px;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  border-color: transparent;
}

/**
*
*
*
**/
.rowListCopy {
  position: relative;
  width: auto;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  -webkit-transition: box-shadow;
  -moz-transition: box-shadow;
  -o-transition: box-shadow;
  transition: box-shadow;
  -webkit-transition: ease-in-out;
  -moz-transition: ease-in-out;
  -o-transition: ease-in-out;
  transition: ease-in-out;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;

}
.rowListCopy:hover {
  box-shadow: 0 12px 50px 0 rgba(51, 34, 93, 0.3);
}
.headerProjectList:hover {
  box-shadow: none !important;
  cursor: initial;
}
.rowListCopy .col1 {
    width: calc((100% - 200px) / 5);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 20px;
    white-space: nowrap;
    overflow: hidden;
}
.rowListCopy .col1 div
{
  overflow: hidden;
}

.groupColDash .col1 {
  width: calc(( 100% - 100px )/ 5);
}
.rowListCopy .col1Align {
  justify-content: center;
}
.rowListCopy .col1 p {
  width: 100%;
  margin: 0;
  text-align: center;
}
.rowListCopy .col1 .label {
  text-align: left !important;
  margin-left: 20px;
}
.rowListCopy .col1 .ville {
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  color: #777777;
  padding-top: 5px;
  display: block;
  text-overflow: ellipsis;
}
.rowListCopy .col1 .statut {
  color: #ffffff;
  font-family: 'Muli', sans-serif;
  padding: 14px 20px;
  border-radius: 50px;
  text-overflow: nowrap;
  font-size: 15px;
  text-overflow: ellipsis;
}
.rowListCopy .col1 .promoteur {
  text-overflow: ellipsis;
}

/**********/
/** DATE **/
/**********/

.rowListCopy .col1 .date {
  font-family: 'Muli', sans-serif;
  color: #777777;
  padding: 0;
  display: inline-block;
  font-size: 15px;

}
.rowListCopy .col1 .date p {
  font-family: 'Muli', sans-serif;
  margin: 0;
  font-size: 11px;
  font-style: italic;
  color: #777777;
}
.rowListCopy .col1 .date img {
  display: inline-block;
  padding-right: 10px;
  pointer-events: none;
}
.rowListCopy .col1 .date .topDate {
  display: flex;
  flex-direction: row;
  align-items: center;

}
.rowListCopy .col1 .date .botDate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-top: 4px;
}
.rowListCopy .col1 .date .botDate p {
    margin-left: 28px;
    display: inline-flex;
}
.rowListCopy .col1 .date .botDate .pinColor {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    display: block;
    position: absolute;
    left: 3px;
}

.rowListCopy .numJob {
    display: flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    padding-right: 20px;
}
.rowListCopy .numJob p {
  margin: 0;
  font-family: 'Muli', sans-serif;
  font-size: 10px;
  font-weight: bold;
  /*color: #353535;*/
  margin-top: 10px;
}


/**
*
* BUILD
*
**/


.containerBuild {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.containerLeftBuild {
  width: 75vw;
  height: calc(100vh - 80px);
  position: relative;
}
.containerLeftBuild .onglet {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 10;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  background-color: #282923;
  height: 52px;
}
.containerLeftBuild .onglet h3 {
  width: fit-content;
  text-align: center;
  cursor: pointer;
  font-family: Muli, sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  padding: 6px 12px;
  border-radius: 8px;
  margin: 0;
  margin-right: 10px;
}
.onglet .saveOnglet {
  position: absolute;
  margin-top: 5px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.onglet .saveOnglet span {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: block;
  position: absolute;
  right: 0;
}
.onglet .saveOnglet p {
  font-family: Muli, sans-serif;
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  text-align: right;
  padding-right: 20px;
  margin: 0;
}
.onglet .ongletRendu {
  background-color: transparent;
  border: 2px solid rgba( 255, 255, 255, 0.5);
  color: rgba( 255, 255, 255, 0.5) !important;
}
.saveIndicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}
.pinSave {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff9f2d;
}
.containerLeftBuild .onglet .ongletSeparate {
  border-left: 1px solid #ffffff;
}
.containerRightBuild {
  width: 25vw;
  position: relative;
  height: calc(100vh - 80px);
  background-color: rgba(51, 34, 93, 0.06);
}
.containerBuild .containerRightBuild .buildInfos,
.containerRightContent .buildInfos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #33225d;
  border-left: 10px solid #ffaa00;
  padding: 5px 0px 10px 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.containerBuild .containerRightBuild .buildInfos input[type=text],
.containerRightContent .buildInfos input[type=text] {
  background-color: transparent;
  border: none;
  margin: 0;
    font-family: Muli,sans-serif;
    font-size: 15px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.containerBuild .containerRightBuild .buildInfos .infoJob,
.containerRightContent .buildInfos .infoJob {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.containerBuild .containerRightBuild .buildInfos .buildInfosProg input[type=text],
.containerRightContent .buildInfos .buildInfosProg input[type=text] {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}
.containerBuild .containerRightBuild .buildInfos .infoJob input[type=text],
.containerRightContent .buildInfos .infoJob input[type=text] {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #ffaa00;
  width: 75px;
}
.containerBuild .containerRightBuild .buildInfos p,
.containerRightContent .buildInfos p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 15px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.containerBuild .containerRightBuild .buildInfos h3,
.containerRightContent .buildInfos h3 {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.containerBuild .containerRightBuild .buildInfos h2,
.containerRightContent .buildInfos h2 {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #ffaa00;
}
.containerBuild .containerRightBuild .buildInfos .buildInfosProg,
.containerRightContent .buildInfos .buildInfosProg {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}
.containerBuild .containerRightBuild .buildInfos .infoBuildVille,
.containerRightContent .buildInfos .infoBuildVille {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}
.containerBuild .containerRightBuild .buildInfos .infoBuildVille img,
.containerRightContent .buildInfos .infoBuildVille img {
  margin-right: 10px;
}
.absoluteBackDash {
  position: absolute;
  z-index: 10;
  top: 15px;
  left: 30px;
  width: 140px;
  height: 50px;
  cursor: pointer;
}
.ace_scrollbar::-webkit-scrollbar-track
{
	border-radius: 2px;
}

.ace_scrollbar::-webkit-scrollbar
{
  width: 6px;
  height: 6px;
  background-color: #272822;
}

.ace_scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 2px;
  background-color: #555555;
}
.containerPartials::-webkit-scrollbar-track
{
  background-color: #ffffff;
  width: 10px;
}
.ace_scrollbar-h {
  /*margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 5px;*/
}
.ace_scrollbar-v {
  /*margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;*/
}
.containerPartials::-webkit-scrollbar
{
	width: 6px;

}
.containerPartials::-webkit-scrollbar-thumb
{
	border-radius: 2px;
	background-color: #d8d8d8;
}
.formBuild {
  width: 100%;
  margin-top: 20px;
}
.containerBuild .containerRightBuild .formBuild .input {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 10px;
  padding: 0 20px;
  outline: none;
}
.containerBuild .containerRightBuild .formBuild .input input {
  width: 100%;
  height: 45px;
  outline: none;
}

.containerBuild .containerRightBuild .formBuild .input input::placeholder
{
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.containerBuild .containerRightBuild .formBuild .input:focus-within input::placeholder {
  opacity: 0.4;
}

.containerBuild .containerRightBuild .formBuild .input input[type=text] {
  padding-left: 20px;
  border-radius: 4px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  background-color: #ffffff;
  font-family: Muli;
  font-size: 15px;
  font-weight: 600;
  color: #33225d;
}
.containerBuild .containerRightBuild .formBuild h4 {
  font-family: Muli, sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #33225d;
  text-transform: uppercase;
  margin-top: 25px;
  margin-bottom: 10px;
}
.containerBuild .containerRightBuild .formBuild .containerPartials h1 {
  font-family: Muli, sans-serif;
  font-size: 15px;
  font-weight: 800;
  text-align: center;
  color: #33225d;
  text-transform: uppercase;
}
.containerBuild .containerRightBuild .formBuild .footerBuild {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #33225d;
  padding: 25px 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.containerBuild .containerRightBuild .formBuild .footerBuild .backBuild {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.containerBuild .containerRightBuild .formBuild .footerBuild .backBuild p {
  font-family: Muli, sans-serif;
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  color: #ffffff;
}
.containerBuild .containerRightBuild .formBuild .footerBuild .backBuild img {
  width: 20px;
  height: auto;
  margin-right: 20px;
}
.formBuild .form-group input {
  width: 100%;
}
.formBuild .createButton {
  position: relative;
  width: fit-content;
  padding: 10px 45px 10px 15px;
  background-color: #ff9f2d;
  color: #ffffff;
  border-radius: 50px;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  border-color: transparent;
  background-image: url(./img/shape.png);
  background-repeat: no-repeat;
  background-position: 85% 50%;
}
.titleClass {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 10px;
}
.titleClass input,
.titleClass span,
.titleClass label {
  font-family: 'Muli', sans-serif;
  font-weight: 600;
  display: block;
  margin: 10px;
  padding: 11px;
  border: none;
  font-size: 22px;
}
.titleClass textarea:focus,
.titleClass input:focus {
  outline: 0;
}
.titleClass input,
.titleClass textarea {
  font-family: 'Muli', sans-serif;
  font-weight: 600 !important;
  font-size: 15px;
  color: #33225d;
  display: block;
  font-weight: 300;
  border-radius: 2px;
  margin: 0;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  background-color: #ffffff;
  transition: padding-top 0.2s ease, margin-top 0.2s ease;
  overflow-x: hidden; /* Hack to make "rows" attribute apply in Firefox. */
}
.titleClass input + label,
.titleClass textarea + label {
  display: block;
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  font-size: 22px;
  width: 100%;
  -webkit-transition: width 0.4s ease;
  transition: width 0.4s ease;
  height: 0px;
}


.titleClass input.questionnaire + label > span,
.titleClass textarea.questionnaire + label > span {
  font-weight: 300;
  margin: 0;
  position: absolute;
  color: #777777;
  font-size: 15px;
  top: -52px;
  left: 50px;
  z-index: 0;
  -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}
.titleClass input.questionnaire + label > img,
.titleClass textarea.questionnaire + label > img {
  display: none;
  margin: 0;
  position: absolute !important;
  top: -35px !important;
  right: 20px;
  left: auto !important;
  z-index: 0;
}
.titleClass input.questionnaire:focus + label > span {
  top: -60px;
  font-size: 12px;
  color: rgba(119, 119, 119, 0.5);
}
.titleClass input.question + label > img,
.titleClass textarea.question + label > img {
  display: none;
  margin: 0;
  position: absolute;
  top: -35px;
  right: 20px;
  z-index: 0;
}
.titleClassAd input.question:valid,
.titleClassAd textarea.question:valid {
  border: 1px solid #ffaa00;
}
.titleClassAd input.question:valid ~ input[type="submit"], .titleClassAd textarea.question:valid ~ input[type="submit"] {
  -webkit-animation: appear 1s forwards;
  animation: appear 1s forwards;
}
.containerPartials {
  height: calc(100vh - 560px);
  width: calc(100% - 40px);
  overflow-y: scroll;
  text-align: center;
  border-radius: 4px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  background-color: #ffffff;
  margin: 0 auto;
}
.containerTopBuild {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.containerMonaco {
  width: 100%;
  height: calc(100vh - 132px);
  position: absolute;
}
.itemPartial {
  cursor: pointer;
  display: flex !important;
  align-items: center;
}
.itemPartial p {
  display: none;
  font-family: Muli, sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
  background-color: #33225d;
  height: fit-content;
  height: -moz-fit-content;
  margin-right: 40px;
  padding: 5px 12px;
  border-radius: 30px;
}
.itemPartial:hover p {
  display: block;
}
.itemPartial span {
  font-family: Muli, sans-serif;
  font-size: 15px;
  padding: 12px;
  color: #777777;
  text-align: left;
  width: 100%;
}
.itemPartial:hover {
  background-color: #dddddd;
}
.itemImageContent {
  cursor: pointer;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
}
.itemImageContent .containerImage {
  width: 50px;
  height: 30px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px;
}
.itemImageContent .containerImage img {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}
.itemImageContent span {
  font-family: Muli, sans-serif;
  font-size: 15px;
  padding: 12px;
  color: #777777;
  text-align: left;
  width: 100%;
}
.itemImageContent p {
  opacity: 0;
  border-radius: 30px;
  background-color: #33225d;
  margin: 0;
  color: #ffffff;
  height: fit-content;
  font-family: Muli, sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  padding: 5px 12px;
}
.itemImageContent:hover {
  background-color: #dddddd;
}
.itemImageContent:hover p {
  opacity: 1;
}
.relative {
  position: relative;
  width: 100%;
}
.containerPreviewPartial {
  width: 300px;
  position: absolute;
  z-index: 10;
  right: calc(100% + 10px);
  top: 0;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}
.containerPreviewPartial img{
  width: 100%;
  height: auto;
}
.containerPreviewImages {
  width: fit-content;
  max-width: 300px;
  position: absolute;
  z-index: 10;
  right: calc(100% + 10px);
  top: 0;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.containerPreviewImages img{
  width: auto;
  height: auto;
  max-height: 350px;
  max-width: 100%;
}
.containerPreviewImages p {
  background-color: #ffffff;
  font-family: Muli,sans-serif;
  font-size: 15px;
  padding: 0;
  color: #777;
  text-align: center;
  width: 100%;
}
#buildCss,
#buildHtml {
  width: 100% !important;
  height: 100% !important;
}
.containerSelectBuild {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 0 auto;
  border-radius: 4px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  background-color: #ffffff;
  margin-bottom: 10px;
}
.selectBuild {
  outline: none;
  margin-left: 20px;
  border: none;
  width: 100%;
}
/**
*
* CONTENT
*
**/
.containerContent {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.containerLeftContent {
  width: 75vw;
  height: calc(100vh - 80px);
  position: relative;
}
.containerRightContent {
  position: relative;
  height: calc(100vh - 80px);
}
.containerLeftContent .onglet {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 10;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  background-color: #282923;
  height: 52px;
}

.containerLeftContent .onglet h3 {
  width: fit-content;
  text-align: center;
  cursor: pointer;
  font-family: Muli, sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  padding: 6px 12px;
  border-radius: 8px;
  margin: 0;
  margin-right: 10px;
}
.containerLeftContent .onglet p {
  font-family: Muli, sans-serif;
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  text-align: right;
  margin: 0;
}
.indicatorIsSaving {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.indicatorIsSaving p {
  font-family: Muli, sans-serif;
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  text-align: right;
  margin: 0;
  color: rgba( 255, 255, 255, 0.5);
}
.containerRightContent .contentFormLien .changeLinks {
  position: relative;
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;
}
.beautifyContent {
  bottom: 20px;
  left: 120px;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 30px;
  background-color: #ff9f2d;
  padding: 5px 15px;
  border: none;
  z-index: 1000;
}
.containerRightContent .contentFormLien .changeLinks input.champsForm {
  font-family: "Muli",sans-serif;
  font-weight: 600!important;
  min-height: 55px;
  font-size: 15px;
  color: #33225d;
}

.containerRightContent .contentFormLien .createButton,
.containerTest .content .bat .createButton {
  position: relative;
  width: fit-content;
  padding: 5px 15px;
  border-radius: 30px;
  background-color: #33225d;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  border-color: transparent;
  font-family: Muli, sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}
.containerRightContent .contentFormLien .champsForm {
  width: 100%;
}
.containerContent .containerRightContent .changeLinks .input {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
}
.containerContent .containerRightContent .changeLinks .input input {
  width: 100%;
  height: 60px;
}
.containerContent .containerRightContent .changeLinks .input input[type=text] {
  padding-left: 20px;
  border-radius: 4px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  background-color: #ffffff;
}
.containerTopContent {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.contentFormLien {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: rgba(51, 34, 93, 0.06);
  height: calc(100vh - 270px);
}
.containerUploadContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  margin-top: 30px;
  padding-bottom: 40px;
}
.containerUploadContent h3 {
  font-family: Muli, sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #33225d;
}
.uploadImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  height: 100%;
}
.uploadImage .empty {
  height: calc(100vh - 425px);
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  margin-bottom: 10px;
  overflow-y: scroll;
}
.uploadImage .empty::-webkit-scrollbar-track
{
	border-radius: 10px;
}

.uploadImage .empty::-webkit-scrollbar
{
  width: 12px;
}
.uploadImage .empty::-webkit-scrollbar-thumb
{
  border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px #d8d8d8;
	background-color: #d8d8d8;
}
.uploadImage .row {
  width: 100%;
}
.dzu-dropzone {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  position: absolute;
  width: 100%;
  right: 0;
}
.dzu-dropzone label {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  position: relative;
  padding: 5px 10px !important;
  border-radius: 30px !important;
  background-color: #33225d !important;
  color: #ffffff !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  border-color: transparent;
  width: fit-content;
  font-family: Muli, sans-serif;
  font-size: 13px;
  font-weight: 600;
}
.dzu-previewContainer {
  display: none;
}
.dzu-previewContainer img {
  width: 100%;
}
.dzu-submitButtonContainer button {
  position: relative;
  padding: 5px 10px !important;
  background-color: #ff9f2d;
  color: #ffffff;
  border-radius: 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  border-color: transparent;
  margin: 0 auto;
  margin-bottom: .5rem;
  width: fit-content;
  font-family: Muli, sans-serif;
  font-size: 13px;
  font-weight: 600;
}
.dzu-previewButton {
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  padding-right: 15px;
  cursor: pointer;
  opacity: 0.9;
  margin: 0 0 2px 10px;
}
.contentButtons {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
}
.contentButtons .saveTry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #9488aa;
  width: 100%;
}
.contentButtons .saveTry input {
  background-color: transparent;
  padding: 18px 0;
  border-radius: 0;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  width: fit-content;
  padding-left: 10px;
  border: none;
}
.contentButtons .footerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #33225d;
  padding: 25px 20px;
}
.contentButtons .footerContent .backDash,
.backDashErr {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.contentButtons .footerContent .backDash img,
.backDashErr img {
  width: 20px;
  height: auto;
  margin-right: 20px;
}
.contentButtons .footerContent .backDash p,
.backDashErr p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.contentButtons .footerContent .send input {
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 30px;
  background-color: #ff9f2d;
  background-image: url(./img/ic-send.png);
  background-repeat: no-repeat;
  background-position: 85% 50%;
  padding: 10px 45px 10px 20px;
  border: none;
}

.containerLeftContent .empty {
  height: 80%;
}

.ongletImgBloc {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.ongletImgBloc .item {
  width: 100%;
  position: relative;
}
.ongletImgBloc .item p {
  cursor: pointer;
  font-family: Muli, sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #000;
  padding: 6px 12px;
  margin: 0;
}
.ongletImgBloc .item:first-child {
  border-right: 1px solid #ffaa00;
}
.containerIncludes {
  flex-direction: column;
}
.containerIncludes form {
  margin: 20px;
}
.containerIncludes h4 {
  font-family: Muli, sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #33225d;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
}
.containerIncludes .relative {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.containerIncludes button {
  flex-direction: column;
    align-items: center !important;
    position: relative;
    padding: 5px 10px !important;
    border-radius: 30px !important;
    background-color: #33225d !important;
    color: #ffffff !important;
    text-decoration: none;
    display: flex;
    align-items: center;
    outline: none;
    border-color: transparent;
    width: -moz-fit-content;
    width: fit-content;
    font-family: Muli, sans-serif;
    font-size: 13px;
    font-weight: 600;
    margin-right: 20px;
    margin-top: 10px;
}
.containerIncludes .relative .containerPartials {
  height: calc(100vh - 515px);
}
/**
*
* TEST
*
**/
.containerTest .cacheButtons {
  display: none;
}
.containerTest {
  position: relative;
  width: 100%;
  height: calc(100vh - 80px);
}
.containerTest .bgTest {
  position: absolute;
  width: 100%;
  height: calc(100vh - 80px);
  top: 0;
  left: 0;
  background-color: rgba(51, 34, 93, 0.95);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.containerTest .bgTest .containerOptions {
  position: relative;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;

}
.containerTest .bgTest .containerOptions h1 {
  font-family: Muli, sans-serif;
  width: 100%;
  font-size: 52px;
  font-weight: 800;
  text-align: center;
  color: #ffffff;
  margin-bottom: 70px;
}
.containerTest .bgTest .containerOptions .containerChoices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.containerTest .bgTest .containerOptions .containerChoices .choice {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 27px;
  box-shadow: 0 20px 20px 0 rgba(8, 8, 8, 0.2);
  background-color: #ffffff;
  cursor: pointer;
  width: 240px;
  height: 310px;
  -webkit-transition: box-shadow 0.2s ease-in-out;
  -moz-transition: box-shadow 0.2s ease-in-out;
  -o-transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;

}

.containerTest .bgTest .containerOptions .containerChoices .choice:hover
{
  box-shadow: 0 2px 2px 0 rgba(8, 8, 8, 0.5);
}


.containerTest .bgTest .containerOptions .containerChoices .choice img {
  max-width: 100%;
  height: auto;
  position: absolute;
  top: 44px;
  z-index: 2;
}


.containerTest .bgTest .containerOptions .containerChoices .choice .text {
  position: absolute;
  text-align: center;
  z-index: 2;
  bottom:  30px;
}
.containerTest .bgTest .containerOptions .containerChoices .choice .text h4 {
  font-family: Muli, sans-serif;
  font-size: 20px;
  font-weight: 800;
  color: #33225d;
  margin: 0;
}
.containerTest .bgTest .containerOptions .containerChoices .choice .text p {
  font-family: Muli, sans-serif;
  font-size: 17px;
  font-weight: 600;
  color: #353535;
}
.containerTest .bgTest .containerOptions .containerChoices .choice .bgOrange {
  position: absolute;
  bottom: 0;
  left: 30%;
  width: 40%;
  min-height: 0px;
  background-color: #ffaa00;
  -webkit-transition: min-height 0.2s ease-in-out;
  -moz-transition: min-height 0.2s ease-in-out;
  -o-transition: min-height 0.2s ease-in-out;
  transition: min-height 0.2s ease-in-out;
}

.containerTest .bgTest .containerOptions .containerChoices .choice:hover .bgOrange
{
  min-height: 10px;
}


.containerTest .bgTest .containerOptions .backToDash {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  width: fit-content;
  padding: 15px;
  margin-top: 55px;
}
.containerTest .bgTest .containerOptions .backToDash img {
  margin-right: 20px;
  width: 20px;
  height: auto;
}
.containerTest .bgTest .containerOptions .backToDash p {
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
}
.testButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.testButtonsContainer form,
.testButtonsContainerAlone form {
  width: 30%;
}
.testButtonsContainer form input,
.testButtonsContainerAlone form input {
  position: relative;
  padding: 20px;
  background-color: #ff9f2d;
  color: #ffffff;
  border-radius: 50px;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  border-color: transparent;
}
.testButtonsContainerAlone {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.testInterneContainer,
.batContainer {
  position: absolute;
  width: 50%;
  left: 25%;
  top: 20%;
  margin: 0 auto;
  background-color: #ffffff;
  z-index: 10;
  padding: 50px;
  text-align: center;
}
.testInterneContainer .containerButtons,
.batContainer .containerButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.testInterneContainer .containerButtons input,
.batContainer .containerButtons input {
  position: relative;
  padding: 20px;
  background-color: #ff9f2d;
  color: #ffffff;
  border-radius: 50px;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  border-color: transparent;
}
.champsTest {
  width: 100%;
}
/**
*
* Zip Folder
*
**/
.containerZipFolder .containerBg{
  width: 100%;
  height: calc(100vh - 80px);
  background-color: rgba(51, 34, 93, 0.95);
  padding-top: calc(((100vh - 80px)/2) - 306px);
}
.containerZipFolder .containerZipper {
  width: 50%;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 20px 50px 0 rgba(8, 8, 8, 0.6);
}
.containerZipFolder .containerZipper .headerDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.containerZipFolder .containerZipper .headerDiv h3 {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 28px;
  font-weight: 800;
  color: #353535;
  margin-left: 20px;
}
.containerZipFolder .containerZipper .containerForm {
  border-radius: 6px;
  background-color: rgba(51, 34, 93, 0.06);
  padding: 30px 0 30px 30px;
  margin-top: 25px;
}
.containerZipFolder .containerZipper .containerForm .containerChild {
  height: 280px;
  overflow-y: scroll;
}
.containerZipFolder .containerZipper .containerForm .containerButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.containerZipFolder .containerZipper .containerForm .containerButtons .btnChild {
  border-radius: 30px;
  background-color: #33225d;
  font-family: Muli, sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  outline: none;
  border-color: transparent;
  padding: 5px 15px;
}
.containerZipFolder .containerZipper .footerZipper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.containerZipFolder .containerZipper .footerZipper .back {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.containerZipFolder .containerZipper .footerZipper .back img {
  width: 20px;
  height: auto;
  margin-right: 20px;
}
.containerZipFolder .containerZipper .footerZipper .back p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #33225d;
}
.containerZipFolder .containerZipper .footerZipper .actionZip {
  border-radius: 30px;
  cursor: pointer;
  background-color: #ff9f2d;
  background-image: url(./img/download-icon.png);
  background-repeat: no-repeat;
  background-position: 85% 50%;
  padding: 15px 50px 15px 15px;
}
.containerZipFolder .containerZipper .footerZipper .actionZip p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
}

.addRemoveButtons {
  display: flex;
  flex-direction: row;
}
.containerZipFolder .createZipFolder {
  margin-top: 20px;
}
.containerButtons input {
  position: relative;
  padding: 20px;
  background-color: #ff9f2d;
  color: #ffffff;
  border-radius: 50px;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  border-color: transparent;
}
.container-zipLine {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.containerChampsZipFolder {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.containerChampsZipFolder .leftSide {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.containerChampsZipFolder .leftSide input,
.containerChampsZipFolder .rightSide input {
  border: none;
  margin-left: 20px;
  width: 100%;
  font-family: Muli, sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #777777;
}
.containerChampsZipFolder .rightSide {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.champsZipFolder input {
  width: 100%;
}
.envoieMail {
  width: 100%;
  height: calc(100vh - 80px);
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
}
.envoieMail .bgEnvoieMail {
  background-color: rgba(51, 34, 93, 0.95);
  width: 100%;
  height: 100%;
  padding-top: calc(((100vh - 80px)/2) - 286px);
  overflow-y: scroll;
}
.envoieMail .bgEnvoieMail::-webkit-scrollbar-track
{
	border-radius: 2px;
}

.envoieMail .bgEnvoieMail::-webkit-scrollbar
{
  width: 6px;
  height: 6px;
  background-color: #ff9f2d;
}

.envoieMail .bgEnvoieMail::-webkit-scrollbar-thumb
{
	border-radius: 2px;
  background-color: #ff9f2d;
}
.envoieMail .bgEnvoieMail::-webkit-scrollbar-track
{
  background-color: rgba(51, 34, 93, 0.95);
  width: 10px;
}
.envoieMail .containerEnvoieMail {
  max-width: 800px;
  width: 60%;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0 20px 50px 0 rgba(8, 8, 8, 0.6);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px;
}
.envoieMail .containerEnvoieMail .headerEnvoieMail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.envoieMail .containerEnvoieMail .headerEnvoieMail h3 {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 28px;
  font-weight: 800;
  color: #353535;
  margin-left: 10px;
}
.envoieMail .containerEnvoieMail .footerEnvoieMail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.envoieMail .containerEnvoieMail .footerEnvoieMail .back {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.envoieMail .containerEnvoieMail .footerEnvoieMail .back img {
  width: 20px;
  height: auto;
  margin-right: 20px;
}
.envoieMail .containerEnvoieMail .footerEnvoieMail .back p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #33225d;
}
.envoieMail .containerEnvoieMail .footerEnvoieMail .buttonEnvoie {
  width: fit-content;
  padding: 10px 45px 10px 15px;
  background-color: #ff9f2d;
  color: #ffffff;
  border-radius: 50px;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  border-color: transparent;
  background-image: url(./img/envoieIconWhite.png);
  background-repeat: no-repeat;
  background-position: 85% 50%;
  cursor: pointer;
}
.envoieMail .containerEnvoieMail .footerEnvoieMail .buttonEnvoie p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.envoieMail .bgEnvoieMail .containerForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px 0;
}
.envoieMail .bgEnvoieMail .containerForm .leftForm,
.envoieMail .bgEnvoieMail .containerForm .rightForm {
  width: 48%;
  border-radius: 6px;
  background-color: rgba(51, 34, 93, 0.06);
  
  padding: 20px 30px 30px 30px;
}
.envoieMail .bgEnvoieMail .containerForm .leftForm .title,
.envoieMail .bgEnvoieMail .containerForm .rightForm .title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.envoieMail .bgEnvoieMail .containerForm .leftForm .title .circle,
.envoieMail .bgEnvoieMail .containerForm .rightForm .title .circle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: #33225d;
  border-radius: 50%;
}
.envoieMail .bgEnvoieMail .containerForm .leftForm .title .circle h4,
.envoieMail .bgEnvoieMail .containerForm .rightForm .title .circle h4 {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 13.5px;
  font-weight: 800;
  color: #ffffff;
}
.envoieMail .bgEnvoieMail .containerForm .leftForm .title p,
.envoieMail .bgEnvoieMail .containerForm .rightForm .title p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #33225d;
  margin-left: 15px;
}
.envoieMail .bgEnvoieMail .containerForm .leftForm .list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 25px;
}
.envoieMail .bgEnvoieMail .containerForm .leftForm .list .item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  margin-bottom: 10px;
  cursor: pointer;
}
.envoieMail .bgEnvoieMail .containerForm .leftForm .list .item p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 15px;
  font-weight: 600;
}
.envoieMail .bgEnvoieMail .containerForm .leftForm .list .item .emptyDiv {
  width: 20px;
}
.envoieMail .bgEnvoieMail .containerForm .rightForm .content .interne,
.envoieMail .bgEnvoieMail .containerForm .rightForm .content .bat,
.envoieMail .bgEnvoieMail .containerForm .rightForm .content .onAcid {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
}
.envoieMail .bgEnvoieMail .containerForm .rightForm .content .interne p,
.envoieMail .bgEnvoieMail .containerForm .rightForm .content .bat p,
.envoieMail .bgEnvoieMail .containerForm .rightForm .content .onAcid p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 13px;
  text-align: center;
  color: #777777;
}
.envoieMail .bgEnvoieMail .containerForm .rightForm .content .interne span,
.envoieMail .bgEnvoieMail .containerForm .rightForm .content .bat span,
.envoieMail .bgEnvoieMail .containerForm .rightForm .content .onAcid span {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 13px;
  text-align: center;
  font-weight: bold;
  color: #33225d;
  margin-bottom: 15px;
}
.envoieMail .bgEnvoieMail .containerForm .rightForm .content .interne textarea,
.envoieMail .bgEnvoieMail .containerForm .rightForm .content .bat textarea {
  margin-top: 15px;
  padding: 17px;
  min-height: 90px;
  border-radius: 4px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  background-color: #ffffff;
  font-family: Muli, sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #777777;
}
.envoieMail .bgEnvoieMail .containerForm .rightForm .content .onAcid .button {
  border-radius: 30px;
  width: fit-content;
  background-color: #33225d;
  padding: 5px 20px;
  cursor: pointer;
  margin-top: 25px;
}
.envoieMail .bgEnvoieMail .containerForm .rightForm .content .onAcid .button p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
}
.envoieOnAcid {
  width: 100%;
  height: calc(100vh - 80px);
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
}
.envoieOnAcid .bgEnvoieOnAcid {
  background-color: rgba(51, 34, 93, 0.95);
  width: 100%;
  height: 100%;
  padding-top: 50px;
}
.envoieOnAcid .containerEnvoieOnAcid {
  width: 35%;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0 20px 50px 0 rgba(8, 8, 8, 0.6);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px;
}
.envoieOnAcid .containerEnvoieOnAcid .headerEnvoieOnAcid {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.envoieOnAcid .containerEnvoieOnAcid .headerEnvoieOnAcid h3 {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 28px;
  font-weight: 800;
  color: #353535;
  margin-left: 10px;
}
.envoieOnAcid .containerContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
  border-radius: 6px;
  background-color: rgba(51, 34, 93, 0.06);
  margin-top: 40px;
}
.envoieOnAcid .containerContent h4 {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: #33225d;
  margin-bottom: 25px;
}
.envoieOnAcid .containerContent p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 15px;
  color: #777777;
  text-align: center;
  margin-bottom: 25px;
}
.envoieOnAcid .containerContent  h2{
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 13px;
  color: #ffffff;
  background-color: #33225d;
  border-radius: 30px;
  padding: 10px 15px;
  cursor: pointer;
}
.envoieOnAcid .containerEnvoieOnAcid .footerEnvoieMail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.envoieOnAcid .containerEnvoieOnAcid .footerEnvoieMail .back {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.envoieOnAcid .containerEnvoieOnAcid .footerEnvoieMail .back img {
  width: 20px;
  height: auto;
  margin-right: 20px;
}
.envoieOnAcid .containerEnvoieOnAcid .footerEnvoieMail .back p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #33225d;
}
.envoieOnAcid .containerEnvoieOnAcid .footerEnvoieMail .buttonEnvoie {
  width: fit-content;
  padding: 10px 45px 10px 15px;
  background-color: #ff9f2d;
  color: #ffffff;
  border-radius: 50px;
  text-decoration: none;
  display: flex;
  align-items: center;
  outline: none;
  border-color: transparent;
  background-image: url(./img/envoieIconWhite.png);
  background-repeat: no-repeat;
  background-position: 85% 50%;
  cursor: pointer;
}
.envoieOnAcid .containerEnvoieOnAcid .footerEnvoieMail .buttonEnvoie p {
  margin: 0;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.ace-monokai .ace_print-margin {
  display: none !important;
}
.titleAccessRegister {
  font-family: 'Muli', sans-serif;
    font-weight: 600;
    color: #33225D;
    font-size: 35px;
    text-align: center;
}
.inputAccessRegister {
  width: 100%;
}






.containerChampsZipFolder input,
.containerChampsZipFolder span,
.containerChampsZipFolder label,
.containerChampsZipFolder textarea {
  font-family: 'Muli', sans-serif;
  font-weight: 600;
  display: block;
  margin: 10px;
  padding: 11px;
  border: none;
  font-size: 22px;
}

.containerChampsZipFolder textarea:focus,
.containerChampsZipFolder input:focus {
  outline: 0;
}
.containerChampsZipFolder input.questionnaire,
.containerChampsZipFolder textarea.questionnaire {
  font-family: 'Muli', sans-serif;
  font-weight: 600 !important;
  font-size: 15px;
  color: #33225d;
  display: block;
  font-weight: 300;
  border-radius: 2px;
  margin: 0;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 4px;
   background-color: #ffffff;
  transition: padding-top 0.2s ease, margin-top 0.2s ease;
  overflow-x: hidden; /* Hack to make "rows" attribute apply in Firefox. */
}
/* Underline and Placeholder */

.containerChampsZipFolder input.questionnaire + label,
.containerChampsZipFolder textarea.questionnaire + label {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    font-size: 22px;
    width: 100%;
    transition: width 0.4s ease;
    height: 0;
    left: 0;
}

.containerChampsZipFolder input.questionnaire + label > span
{
  position: absolute !important;
  z-index: 2 !important;
  left: 60px;
  top: -40px;
  font-size: 12px;
  color: rgba(119, 119, 119, 0.5);
}

.containerChampsZipFolder input.questionnaire:focus + label,
.containerChampsZipFolder textarea.questionnaire:focus + label {
  width: 100%;
}

.containerChampsZipFolder input.questionnaire:focus,
.containerChampsZipFolder input.questionnaire:valid {
  padding-top: 20px;
  /* border: 1px solid #ffaa00 !important; */
}

.containerChampsZipFolder textarea.questionnaire:valid,
.containerChampsZipFolder textarea.questionnaire:focus {
  margin-top: 35px;
}

.containerChampsZipFolder input.questionnaire:focus + label > span,
.containerChampsZipFolder input.questionnaire:valid + label > span {
  top: -52px;
  font-size: 12px;
  color: rgba(119, 119, 119, 0.5);
}
/*
.containerChampsZipFolder input.questionnaire:valid + label > img {
  display: block;  
}
*/

.containerChampsZipFolder textarea.questionnaire:focus + label > span,
.containerChampsZipFolder textarea.questionnaire:valid + label > span {
  top: -150px;
  font-size: 22px;
  color: #333;
}
/*
.containerChampsZipFolder input.questionnaire:valid,
.containerChampsZipFolder textarea.questionnaire:valid {
  border: 1px solid #ffaa00;
}
*/
.containerChampsZipFolder input.questionnaire:invalid,
.containerChampsZipFolder textarea.questionnaire:invalid {
  box-shadow: none;
}

.containerChampsZipFolder input.questionnaire + label > span,
.containerChampsZipFolder textarea.questionnaire + label > span {
  font-weight: 300;
  margin: 0;
  position: absolute;
  color: #777777;
  font-size: 15px;
  z-index: 0;
  -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
}
.containerChampsZipFolder input.questionnaire + label > img,
.containerChampsZipFolder textarea.questionnaire + label > img {
  display: none;
  margin: 0;
  position: absolute !important;
  top: -35px !important;
  right: 10px;
  left: auto !important;
  z-index: 0;
}
#input-url {
  padding-right: 35px;
}
.containerChampsZipFolder input.questionnaire:valid ~ input[type="submit"], .containerChampsZipFolder textarea.questionnaire:valid ~ input[type="submit"] {
  -webkit-animation: appear 1s forwards;
  animation: appear 1s forwards;
}

.containerChampsZipFolder input.questionnaire:invalid ~ input[type="submit"], .containerChampsZipFolder textarea.questionnaire:invalid ~ input[type="submit"] {
  display: none;
}
.containerChampsZipFolder .inputWithIcon {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
} 
.containerChampsZipFolder .inputWithIcon img {
  position: absolute;
  left: 21px;
  top: 15px;
}
.containerChampsZipFolder .inputWithIcon input[type=text]  {
  height: 55px;
  padding-left: 60px;
  border-radius: 4px;
  background-color: #ffffff;
}

.containerZipFolder .containerZipper .containerForm .containerChild::-webkit-scrollbar-track
{
	border-radius: 2px;
}

.containerZipFolder .containerZipper .containerForm .containerChild::-webkit-scrollbar
{
  
  width: 6px;
  height: 6px;
  background-color: transparent;
}

.containerZipFolder .containerZipper .containerForm .containerChild::-webkit-scrollbar-thumb
{
	border-radius: 2px;
  background-color: #33225d;
}
.containerZipFolder .containerZipper .containerForm .containerChild::-webkit-scrollbar-track
{
  background-color: transparent;
  width: 10px;
}
.ajustZipPadding {
  padding-right: 10px;
}
.separateBoth {
  width: 1px;
  height: 35px;
  background-color:  #d8d8d8;
}
.previewScreenArchive {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 200px;
  width: 150px;
  z-index: 50;
}
.previewScreenArchive img {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.subjectBat {
  width: 100%;
  margin-top: 10px;
  border-radius: 4px;
  border: solid 1px rgba(215, 211, 223, 0.5);
  background-color: #ffffff;
  font-family: Muli, sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #777777;
  padding: 17px;
}
.bandeauGestion {
  position: absolute;
  z-index: 10;
  width: 70px;
  top: 50%;
  height: 60px;
  bottom: 0;
  overflow: hidden;
}
.gestionRightContent {
  position: relative;
  z-index: 10;
  top: 5px;
  height: 50px;
  left: 70px;
  width: 50px;
  cursor: pointer;
  background-color: rgba(255, 161, 45, .5);
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: left 0.125s ease-in-out;
}
.bandeauGestion:hover .gestionRightContent {
  left: 10px;
}
.containerGestionFenetre {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gestionRightContent img {
  width: 100%;
  height:  auto;
}
.deleteFolderIcon {
  height: 25px;
  width: 25px;
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.deleteFolderIcon img {
  width: 100%;
  height: auto;
}
#insererURL {
  background-image: url('./img/ic-save.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: #33225d;
  position: absolute;
  right: 0;
  width: 50px;
  height: 100%;
  border-radius: 0;
  border: solid 1px rgba(215, 211, 223, 0.5);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.loadingDashboard {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
}


/*
.ace_scroller {
  right: 90px !important;
}
.ace_content, .ace_layer {
  padding-right: 90px;
}
#buildHtml {
  margin-right: 90px;
}
.ace_text-layer {
  padding: -30px !important;
}
*/